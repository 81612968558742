import { hideNotification, showNotification } from '@/store/app/reducer';
import { IAppContextModel } from '@/store/app/types';
import { AlertColor } from '@mui/material/Alert';
import { ReactNode, createContext, lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
const Notification = lazy(() => import('@/common/components/molecules/notification/notification').then((module) => ({default: module['Notification']})));

type NotifyPayload = {  message: string,	type: AlertColor };

type NotifyType = { notify: (data: NotifyPayload) => void };

export const NotificationContext = createContext({} as NotifyType);

interface Props {
	children: ReactNode;
}

export const NotificationContextProvider = ({ children }: Props) => {

	const appContext: IAppContextModel = useSelector((state: any) => state.appContext);
	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(hideNotification());
	};

	const notify = (data: NotifyPayload) => {
		dispatch(showNotification({
			message: data.message,
			type: data.type
		}));
	};

	return (
		<NotificationContext.Provider 
			value={{notify}}
		>
			{children}
			<Notification 
				type={appContext.notification.type}
				message={appContext.notification.message}
				isOpen={appContext.notification.showNotification}
				onClose={handleClose}
			/>
		</NotificationContext.Provider>
	);
};